import React from "react";
import styles from "./Hero.module.scss";
import heroImage from "../../images/hero-image.svg";
import { Link } from "react-scroll";

const Hero = () => (
  <div className={styles.Hero} id="hero">
    <div className={styles.heroBlock}>
    <div className={styles.heroImageMobile}>
        <img src={heroImage} alt="Treequeue" />
      </div>
      <div className={styles.heroContent}>
        <h1 className={styles.heroTitle}>
          Unlock Success with Expert-Led Talent
        </h1>
        <p className={styles.heroText}>
          Elevate your organization to new heights with our curated recruitment
          solutions. Your success story begins with the right team by your side.
        </p>
        <Link
          to="contact"
          activeClass="active"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
          className={styles.heroBtn}
        >
          Contact Us
        </Link>
      </div>
      <div className={styles.heroImage}>
        <img src={heroImage} alt="Treequeue" />
      </div>
    </div>
  </div>
);

export default Hero;
