import React from "react";
import styles from "./Nav.module.scss";
import { Link } from "react-scroll";

const Nav = () => (
  <nav className={styles.Nav}>
    <ul className={styles.navList}>
      <li className={styles.navItem}>
        <Link
          to="about"
          activeClass="active"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
          className={`${styles.navLink}`}
        >
          About
        </Link>
      </li>
      <li className={styles.navItem}>
        <Link
          to="services"
          activeClass="active"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
          className={styles.navLink}
        >
          Services
        </Link>
      </li>
      <li className={styles.navItem}>
        <Link
          to="contact"
          activeClass="active"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
          className={`${styles.navLink} ${styles.hirebtn}`}
        >
          Contact Us
        </Link>
      </li>
    </ul>
  </nav>
);

export default Nav;
