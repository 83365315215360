import React from "react";
import { useState, useEffect } from "react";
import styles from "./Header.module.scss";
import Nav from "../Nav/Nav";
import logo from "../../images/logo-treequeue.svg";
import { Link, animateScroll as scroll } from "react-scroll";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollThreshold = 100; // Adjust this value based on when you want the shadow to appear

      // Set the state based on the scroll position
      setIsScrolled(scrollPosition > scrollThreshold);
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <header className={`${styles.Header} ${isScrolled ? "scrolled" : ""}`}>
      <div className={styles.headerBlock}>
        <Link
          to="hero"
          activeClass="active"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          className={`${styles.logo} ${isScrolled ? "scrolledLogo" : ""}`}
        >
          <img src={logo} alt="Treequeue" />
        </Link>
        <Nav />
      </div>
    </header>
  );
};

export default Header;
