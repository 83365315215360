import React from "react";
import styles from "./Social.module.scss";
import linkedinLogo from "../../images/linkedin-logo.svg";
import xLogo from "../../images/x-logo.svg";
import facebookLogo from "../../images/facebook-logo.svg";

const Social = () => (
  <div className={styles.Social}>
    <div className={styles.socialBlock}>
      <div className={styles.socialContent}>
        <p className={styles.socialText}>Follow us on social media</p>
        <ul className={styles.socialList}>
          <li className={styles.socialItem}>
            <a href="#" className={styles.socialLink}>
              <img src={linkedinLogo} alt="LinkedIn" />
            </a>
          </li>
          <li className={styles.socialItem}>
            <a href="#" className={styles.socialLink}>
              <img src={xLogo} alt="X" />
            </a>
          </li>
          <li className={styles.socialItem}>
            <a href="#" className={styles.socialLink}>
              <img src={facebookLogo} alt="Facebook" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
);

Social.propTypes = {};

Social.defaultProps = {};

export default Social;
