import React from "react";
import styles from "./Contact.module.scss";
import contactImage from "../../images/contact-image.svg";

const Contact = () => (
  <div className={styles.Contact} id="contact">
    <div className={styles.contactBlock}>
      <div className={styles.contactContent}>
        <h2 className={styles.contactTitle}>
          Let's Connect: Your Future Workforce Starts with a Conversation
        </h2>
        <p className={styles.contactText}>
          Ready to take your organization to the next level? We're here to help.
        </p>
        <div className={styles.emailSpace}>
            <h4>
                Send your queries to:
            </h4>
            <a href="mailto:contact@treequeue.com" className={styles.contactEmail}>contact@treequeue.com</a>
        </div>
        <div className={styles.emailSpace}>
            <h4>
                Candidates can drop your CVs to: 
            </h4>
            <a href="mailto:recruitment@treequeue.com" className={styles.contactEmail}>recruitment@treequeue.com</a>
        </div>
      </div>
      <div className={styles.contactImage}>
        <img src={contactImage} alt="Contact Treequeue" />
      </div>
    </div>
  </div>
);

export default Contact;
