import React from "react";
import styles from "./Services.module.scss";
import servicesImage from "../../images/services-image.svg";
import blob from "../../images/blob-2.svg";

const Services = () => (
  <div className={styles.Services} id="services">
    <div className={styles.blobOverlay}>
      <img src={blob} />
    </div>
    <div className={styles.servicesBlock}>
      <div className={styles.servicesContent}>
        <h2 className={styles.servicesTitle}>
          Seamless Solutions, Engineered by Experts
        </h2>
        <div className={styles.servicesText}>
          <div className={styles.serviceCard}>
            <h4>Sourcing</h4>
            <p>With our targeted sourcing strategies, we uncover hidden talent gems for your roles. Leveraging expertice on technology and our extensive network, we reach passive candidates and active job seekers alike, ensuring a diverse and qualified candidate pool for your consideration.</p>
          </div>
          <div className={styles.serviceCard}>
            <h4>Interview Facilitation</h4>
            <p>Our expert team handles every aspect of the interview process, from scheduling to assessment. Using proven techniques and tailored assessments, we evaluate candidates objectively and efficiently, empowering you to make informed hiring decisions with confidence and ease.</p>
          </div>
          <div className={styles.serviceCard}>
            <h4>Staffing</h4>
            <p>Our tailored contract staffing solutions cater to the dynamic needs of your projects. Leveraging our vast network, we expediently identify and deploy skilled professionals perfectly suited to meet your staffing demands. Our flexible options ensure seamless integration and sustained project success, every step of the way.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Services;
