import React from "react";
import styles from "./About.module.scss";
import aboutImage from "../../images/about-image.svg";
import blob from "../../images/blob-1.svg";

const About = () => (
  <div className={styles.About} id="about">
    <div className={styles.blobOverlay}>
      <img src={blob} />
    </div>
    <div className={styles.aboutBlock}>
      <div className={styles.aboutImage}>
        <img src={aboutImage} alt="About Treequeue" />
      </div>
      <div className={styles.aboutContent}>
        <h2 className={styles.aboutTitle}>
          Who We Are: Trailblazing Recruitment Powered by Expertise
        </h2>
        <p className={styles.aboutText}>
          At Treequeue, we're not just recruiters; we're industry experts on a
          mission. We are a team of seasoned professionals dedicated to
          reshaping the recruitment landscape. We redefine recruitment by
          harnessing the power of industry technical experts to identify and
          connect you with the most qualified candidates for your organization.
        </p>
        <p className={styles.aboutText}>
          We are on a mission to bridge the gap between top-tier talent and
          forward-thinking companies.
        </p>
      </div>
    </div>
  </div>
);

export default About;
