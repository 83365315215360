import React from "react";
import styles from "./Footer.module.scss";
import Social from "../Social/Social";

const Footer = () => (
  <div className={styles.Footer}>
    <div className={styles.footerBlock}>
      <div className={styles.footerContent}>
        <div className={styles.copyright}>
          <p>
            © 2023 Copyright Prarnide Solutions Private Limited. All Rights
            Reserved{" "}
          </p>
        </div>
        {/* <div className={styles.footerLinks}>
          <ul className={styles.footerList}>
            <li className={styles.footerItem}>
              <a href="#" className={styles.footerLink}>
                Terms of Use
              </a>
            </li>
            <li className={styles.footerItem}>
              <a href="#" className={styles.footerLink}>
                Privacy Policy
              </a>
            </li>
          </ul>
        </div> */}
      </div>
      {/* <Social /> */}
    </div>
  </div>
);

export default Footer;
